const LeftArrowSVG = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="15"
			height="43"
			viewBox="0 0 15 43"
			fill="none"
		>
			<g clipPath="url(#clip0_126_2571)">
				<path
					d="M5.06683 21.5987C5.06683 21.5984 5.06723 21.5982 5.06747 21.5985L13.2285 29.7595C13.619 30.15 13.619 30.7832 13.2285 31.1737L12.1093 32.2929C11.7187 32.6834 11.0856 32.6834 10.6951 32.2929L0.707107 22.3049C0.316583 21.9144 0.316583 21.2813 0.707107 20.8907L10.6948 10.903C11.0854 10.5124 11.7188 10.5125 12.1093 10.9033L13.229 12.0238C13.6193 12.4143 13.6192 13.0473 13.2287 13.4377L5.06747 21.599C5.06723 21.5992 5.06683 21.5991 5.06683 21.5987Z"
					fill="#262829"
				/>
			</g>
			<defs>
				<clipPath id="clip0_126_2571">
					<rect width="43" height="15" fill="white" transform="matrix(0 -1 1 0 0 43)" />
				</clipPath>
			</defs>
		</svg>
	)
}

export default LeftArrowSVG
